import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allWeeksAPI } from "../api";

export const getSubjectAllWeeks = createAsyncThunk(
  "subjectAllWeeks/fetch",
  async (monthData, thunkAPI) => {
    try {
      const res = await allWeeksAPI(monthData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
    subjectAllWeeks: null,
    monthId: null,
    loading: false, 
    hasErrors: false,
    error: "",
};

const slice = createSlice({
  name: "subjectAllWeeks",
  initialState,
  reducers: {
    resetAllWeeks: (state) => {
      state = initialState;
      return state;
    },
    setMonthId: (state, {payload}) => {
        state.monthId = payload;
        return state;
    }
  },
  extraReducers: {
    [getSubjectAllWeeks.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getSubjectAllWeeks.fulfilled]: (state, { payload }) => {
        state.subjectAllWeeks = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getSubjectAllWeeks.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAllWeeks, setMonthId } = slice.actions;

// Selectors
export const subjectAllWeeksSelector = (state) => state.subjectAllWeeks.subjectAllWeeks;
export const subjectAllWeeksLoadingSelector = (state) => state.subjectAllWeeks.loading;
export const subjectAllWeeksMonthIdSelector = (state) => state.subjectAllWeeks.monthId;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
