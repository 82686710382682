import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { citiesAPI } from "../api";

export const getKindergartensCities = createAsyncThunk(
  "kindergartensCities/fetch",
  async (regionId, thunkAPI) => {
    try {
      const res = await citiesAPI(regionId);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  cities: null,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "kindergartensCities",
  initialState,
  reducers: {},
  extraReducers: {
    [getKindergartensCities.pending]: (state) => {
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getKindergartensCities.fulfilled]: (state, { payload }) => {
      state.cities = payload;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getKindergartensCities.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const kindergartensCitiesSelector = (state) => state.kindergartensCities.cities;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
