import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allUsersAPI } from "../api";

export const getKindergartensAllUsers = createAsyncThunk(
  "kindergartensAllUsers/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await allUsersAPI();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  users: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "kindergartensAllUsers",
  initialState,
  reducers: {},
  extraReducers: {
    [getKindergartensAllUsers.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getKindergartensAllUsers.fulfilled]: (state, { payload }) => {
      state.users = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getKindergartensAllUsers.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const kindergartensAllUsersSelector = (state) => state.kindergartensAllUsers.users;
export const kindergartensAllUsersLoadingSelector = (state) => state.kindergartensAllUsers.loading;
export const kindergartensAllUsersPaginationSelector = (state) => state.kindergartensAllUsers.pagination;
