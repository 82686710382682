import { 
    // httpDelete, 
    httpGet, 
    // httpPost 
} from "../../../api";

export const allSubjectsAPI = (params) =>
  httpGet({
    url: "/api/admin/subjects",
    params
  });

export const ageClassesAPI = () =>
    httpGet({
      url: "/api/admin/age_classes"
    });
