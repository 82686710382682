import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateSubjectAPI } from "../api";

export const updateSubject = createAsyncThunk(
  "updateSubject/fetch",
  async (subjectData, thunkAPI) => {
    console.log('...starting update');
    
    // const jsonData = data.forEach(item => {
    //   return {

    //   }
    // });
    try {
      const { id, data } = subjectData;
      console.log('check: ', data);
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      console.log(formData);
      const res = await updateSubjectAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  subject: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateSubject",
  initialState,
  reducers: {
    setUpdateSubjectId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateSubject.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateSubject.fulfilled]: (state, { payload }) => {
      state.subject = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateSubject.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateSubjectId, resetUpdated } = slice.actions;

// Selectors
export const updateSubjectLoadingSelector = (state) => state.updateSubject.loading;
export const updateSubjectHasErrorsSelector = (state) => state.updateSubject.hasErrors;
export const updateSubjectErrorMessageSelector = (state) => state.updateSubject.error;
export const updatedSubjectSelector = (state) => state.updateSubject.subject;
export const updateSubjectIdSelector = (state) => state.updateSubject.id;
