import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addUserAPI } from "../api";

export const addUser = createAsyncThunk(
  "addUser/fetch",
  async (userFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(userFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await addUserAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  user: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    resetAddedUser: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addUser.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedUser } = slice.actions;

// Selectors
export const addUserLoadingSelector = (state) => state.addSubject.loading;
export const addUserHasErrorsSelector = (state) => state.addSubject.hasErrors;
export const addUserErrorMessageSelector = (state) => state.addSubject.error;
export const addedUserSelector = (state) => state.addUser.user;
