import styled from 'styled-components/macro';

export const SidebarTitle = styled.h3`
    height: ${({collapsed}) => collapsed ? '0' : '100%'};
    opacity: ${({collapsed}) => collapsed ? 0 : 1};
    margin: 8px 0 12px 0;
    transition: all 0.2s ease-in-out;

    color: #ffffff;
    text-align: center;
    font-size: 22px;    
`;