import { useSelector } from 'react-redux';
import { SidebarTitle } from '../atoms';

import { collapsedSelector } from '../slices/sidebarSlice';
import { userSelector } from '../../auth/slices/authSlice';

export const Title = () => {

    const collapsed = useSelector(collapsedSelector);
    const user = useSelector(userSelector);

    return (
        <SidebarTitle level={3} collapsed={collapsed}>{user ? user.roles[0].name : "unknown"}</SidebarTitle> 
    )
}