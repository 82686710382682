import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allSubjectsAPI } from "../api";

export const getAllSubjects = createAsyncThunk(
  "subjectAllSubjects/fetch",
  async (params, thunkAPI) => {
    try {
      const res = await allSubjectsAPI(params);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  subjects: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "subjectAllSubjects",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllSubjects.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllSubjects.fulfilled]: (state, { payload }) => {
      state.subjects = payload.data;
      state.pagination = {
        current: payload.meta.current_page,
        total: payload.meta.total,
        pageSize: payload.meta.per_page
      };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllSubjects.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const subjectAllSubjectsSelector = (state) => state.subjectAllSubjects.subjects;
export const subjectAllSubjectsLoadingSelector = (state) => state.subjectAllSubjects.loading;
export const subjectAllSubjectsPaginationSelector = (state) => state.subjectAllSubjects.pagination;
