import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addKindergartenAPI } from "../api";

export const addKindergarten = createAsyncThunk(
  "addKindergarten/fetch",
  async (kindergartenFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(kindergartenFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await addKindergartenAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  kindergarten: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addKindergarten",
  initialState,
  reducers: {
    resetAddedKindergarten: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addKindergarten.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addKindergarten.fulfilled]: (state, { payload }) => {
      state.kindergarten = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addKindergarten.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedKindergarten } = slice.actions;

// Selectors
export const addKindergartenLoadingSelector = (state) => state.addKindergarten.loading;
export const addKindergartenHasErrorsSelector = (state) => state.addKindergarten.hasErrors;
export const addKindergartenErrorMessageSelector = (state) => state.addKindergarten.error;
export const addedKindergartenSelector = (state) => state.addKindergarten.kindergarten;
