import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteMonthAPI } from "../api";

export const deleteMonth = createAsyncThunk(
  "deleteMonth/fetch",
  async (id, thunkAPI) => {
    try {
      const res = await deleteMonthAPI(id);
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "deleteMonth",
  initialState,
  reducers: {
    resetDeleted: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [deleteMonth.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteMonth.fulfilled]: (state, { payload }) => {
      state.id = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteMonth.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetDeleted } = slice.actions;

// Selectors
export const deleteMonthLoadingSelector = (state) => state.deleteMonth.loading;
export const deleteMonthHasErrorsSelector = (state) => state.deleteMonth.hasErrors;
export const deleteMonthErrorMessageSelector = (state) => state.deleteMonth.error;
export const deleteMonthIdSelector = (state) => state.deleteMonth.id;
