import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteTestAPI } from "../api";

export const deleteTest = createAsyncThunk(
  "deleteTest/fetch",
  async (id, thunkAPI) => {
    try {
      const res = await deleteTestAPI(id);
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "deleteTest",
  initialState,
  reducers: {
    resetDeleted: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [deleteTest.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteTest.fulfilled]: (state, { payload }) => {
      state.id = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteTest.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetDeleted } = slice.actions;

// Selectors
export const deleteTestLoadingSelector = (state) => state.deleteTest.loading;
export const deleteTestHasErrorsSelector = (state) => state.deleteTest.hasErrors;
export const deleteTesttErrorMessageSelector = (state) => state.deleteTest.error;
export const deleteTestIdSelector = (state) => state.deleteTest.id;
