import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteKindergartenAPI } from "../api";

export const deleteKindergarten = createAsyncThunk(
  "deleteKindergarten/fetch",
  async (id, thunkAPI) => {
    try {
      const res = await deleteKindergartenAPI(id);
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "deleteKindergarten",
  initialState,
  reducers: {
    resetDeleted: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [deleteKindergarten.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteKindergarten.fulfilled]: (state, { payload }) => {
      state.id = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteKindergarten.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetDeleted } = slice.actions;

// Selectors
export const deleteKindergartenLoadingSelector = (state) => state.deleteKindergarten.loading;
export const deleteKindergartenHasErrorsSelector = (state) => state.deleteKindergarten.hasErrors;
export const deleteKindergartenErrorMessageSelector = (state) => state.deleteKindergarten.error;
export const deleteKindergartenIdSelector = (state) => state.deleteKindergarten.id;
