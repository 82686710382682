import { 
    // httpDelete, 
    httpGet, 
    httpPost,
    // httpPut
 } from "../../../api";


export const allSubjectTestsOfDayAPI = (params) =>
  httpGet({
    url: "/api/admin/tests",
    params
  });

export const addChallengeAPI = (data) =>
  httpPost({
      url: "/api/admin/tests",
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });

export const ageClassesAPI = () =>
    httpGet({
        url: "/api/admin/age_classes"
    });

export const allSubjectsAPI = (params) =>
  httpGet({
    url: "/api/admin/subjects",
    params
  });

export const allMonthsAPI = (params) =>
    httpGet({
        url: "/api/admin/months",
        params
    });

export const allWeeksAPI = (params) =>
    httpGet({
        url: "/api/admin/weeks",
        params
    });

export const allDaysAPI = (params) =>
    httpGet({
        url: "/api/admin/days",
        params
    });

// export const getSubjectAPI = (id) =>
//   httpGet({
//     url: `/api/admin/subjects/${id}`
//   });



