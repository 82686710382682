import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateKindergartenAPI } from "../api";

export const updateKindergarten = createAsyncThunk(
  "updateKindergarten/fetch",
  async (kindergartenData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = kindergartenData;
      console.log('check: ', data);
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      console.log(formData);
      const res = await updateKindergartenAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  kindergarten: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateKindergarten",
  initialState,
  reducers: {
    setUpdateKindergartenId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateKindergarten.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateKindergarten.fulfilled]: (state, { payload }) => {
      state.kindergarten = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateKindergarten.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateKindergartenId, resetUpdated } = slice.actions;

// Selectors
export const updateKindergartenLoadingSelector = (state) => state.updateKindergarten.loading;
export const updateKindergartenHasErrorsSelector = (state) => state.updateKindergarten.hasErrors;
export const updateKindergartenErrorMessageSelector = (state) => state.updateKindergarten.error;
export const updatedKindergartenSelector = (state) => state.updateKindergarten.kindergarten;
export const updateKindergartenIdSelector = (state) => state.updateKindergarten.id;
