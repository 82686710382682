// import { StyledIcon } from '../atoms';
import * as icons from '@ant-design/icons';
import React from 'react'


export const setIcon = (iconName = 'CiCircleOutlined', colorTheme = 'dark') => {

    for (let prop in icons) {
        if (iconName === prop) {
            return (
                <>
                    {React.createElement(icons[prop], {
                        className: 'sidebar-icon',
                        style: {color: '#ffffff'},
                        })
                    }
                </>
            )
        }
    }

}