import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateWeekAPI } from "../api";

export const updateWeek = createAsyncThunk(
  "updateWeek/fetch",
  async (weekData, thunkAPI) => {
    try {
      const { id, data } = weekData;
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      const res = await updateWeekAPI(id, formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  week: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateWeek",
  initialState,
  reducers: {
    setUpdateWeekId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateWeek.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateWeek.fulfilled]: (state, { payload }) => {
      state.week = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateWeek.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateWeekId, resetUpdated } = slice.actions;

// Selectors
export const updateWeekLoadingSelector = (state) => state.updateWeek.loading;
export const updateWeekHasErrorsSelector = (state) => state.updateWeek.hasErrors;
export const updateWeekErrorMessageSelector = (state) => state.updateWeek.error;
export const updateWeekSelector = (state) => state.updateWeek.week;
export const updateWeekIdSelector = (state) => state.updateWeek.id;
