import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRoleAPI } from "../api";

export const deleteRole = createAsyncThunk(
  "deleteRole/fetch",
  async (id, thunkAPI) => {
    try {
      const res = await deleteRoleAPI(id);
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "deleteRole",
  initialState,
  reducers: {
    resetDeleted: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [deleteRole.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteRole.fulfilled]: (state, { payload }) => {
      state.id = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [deleteRole.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetDeleted } = slice.actions;

// Selectors
export const deleteRoleLoadingSelector = (state) => state.deleteRole.loading;
export const deleteRoleHasErrorsSelector = (state) => state.deleteRole.hasErrors;
export const deleteRoleErrorMessageSelector = (state) => state.deleteRole.error;
export const deleteRoleIdSelector = (state) => state.deleteRole.id;
