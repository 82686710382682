import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ageClassesAPI } from "../api";

export const getAgeClasses = createAsyncThunk(
  "ageClasses/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await ageClassesAPI();
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  ageClasses: null,
  selectedAgeClass: null,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "ageClasses",
  initialState,
  reducers: {},
  extraReducers: {
    [getAgeClasses.pending]: (state) => {
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAgeClasses.fulfilled]: (state, { payload }) => {
      state.ageClasses = payload;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAgeClasses.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const ageClassesSelector = (state) => state.ageClasses.ageClasses;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
