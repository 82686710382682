import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allSubjectTestsOfDayAPI } from "../api";

export const getAllSubjectTestsOfDay = createAsyncThunk(
  "subjectEditTestsOfDay/fetch",
  async (dayId, thunkAPI) => {
    try {
      const res = await allSubjectTestsOfDayAPI(dayId);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  subjectEditTestsOfDay: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "subjectEditTestsOfDay",
  initialState,
  reducers: {
    resetSubjectEditTestsOfDay: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAllSubjectTestsOfDay.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getAllSubjectTestsOfDay.fulfilled]: (state, { payload }) => {
        state.subjectEditTestsOfDay = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
        return state;
    },
    [getAllSubjectTestsOfDay.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetSubjectEditTestsOfDay } = slice.actions;

// Selectors
export const subjectEditTestsOfDaySelector = (state) => state.subjectEditTestsOfDay.subjectEditTestsOfDay;
export const subjectEditTestsOfDayLoadingSelector = (state) => state.subjectEditTestsOfDay.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
