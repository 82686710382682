import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { citiesAPI } from "../api";

export const getUsersCities = createAsyncThunk(
  "usersCities/fetch",
  async (regionId, thunkAPI) => {
    try {
      const res = await citiesAPI(regionId);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  cities: null,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "usersCities",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersCities.pending]: (state) => {
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getUsersCities.fulfilled]: (state, { payload }) => {
      state.cities = payload;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getUsersCities.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const usersCitiesSelector = (state) => state.usersCities.cities;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
