import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateSidebarAPI } from "../api";

export const updateSidebar = createAsyncThunk(
  "updateSidebar/fetch",
  async (sidebarData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = sidebarData;
      const res = await updateSidebarAPI(id, data);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  sidebar: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateSidebar",
  initialState,
  reducers: {
    setUpdateSidebarId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateSidebar.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateSidebar.fulfilled]: (state, { payload }) => {
      state.sidebar = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateSidebar.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateSidebarId, resetUpdated } = slice.actions;

// Selectors
export const updateSidebarLoadingSelector = (state) => state.updateSidebar.loading;
export const updateSidebarHasErrorsSelector = (state) => state.updateSidebar.hasErrors;
export const updateSidebarErrorMessageSelector = (state) => state.updateSidebar.error;
export const updatedSidebarSelector = (state) => state.updateSidebar.sidebar;
export const updateSidebarIdSelector = (state) => state.updateSidebar.id;
