import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allSubjectTestsOfDayAPI } from "../api";

export const getAllSubjectTestsOfDay = createAsyncThunk(
  "editChallengeAllSubjectTestsOfDay/fetch",
  async (dayId, thunkAPI) => {
    try {
      const res = await allSubjectTestsOfDayAPI(dayId);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  editChallengeAllSubjectTestsOfDay: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "editChallengeAllSubjectTestsOfDay",
  initialState,
  reducers: {
    resetAllSubjectTestsOfDay: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAllSubjectTestsOfDay.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getAllSubjectTestsOfDay.fulfilled]: (state, { payload }) => {
        state.editChallengeAllSubjectTestsOfDay = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getAllSubjectTestsOfDay.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAllSubjectTestsOfDay } = slice.actions;

// Selectors
export const editChallengeAllSubjectTestsOfDaySelector = (state) => state.editChallengeAllSubjectTestsOfDay.editChallengeAllSubjectTestsOfDay;
export const editChallengeAllSubjectTestsOfDayLoadingSelector = (state) => state.editChallengeAllSubjectTestsOfDay.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
