import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { signInAPI } from "../api";

export const signIn = createAsyncThunk(
  "user/signIn",
  async (formData, thunkAPI) => {
    try {
      const res = await signInAPI(formData);
      console.log(res);
      if (res.status === 200) {
        await Cookies.set("access-token", res.data.access_token, {
          expires: 86400, 
        });
      }
      return res.data.user.original;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  currentUser: null,
  hasErrors: false,
  error: "",
  isAuthenticated: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signOut: (state) => {
      Cookies.remove("access-token");
      state.isAuthenticated = false;
      state.currentUser = null;
      return state;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      Cookies.remove("access-token");
      state.currentUser = null;
      return state;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      let token = Cookies.get("access-token");
      state.hasErrors = false;
      state.error = "";
      state.currentUser = payload;
      if (token) {
        state.isAuthenticated = true;
      }
      return state;
    },
    [signIn.rejected]: (state, { error }) => {
      Cookies.remove("access-token");
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { signOut } = slice.actions;

// Selectors
export const userSelector = (state) => state.user.currentUser;
export const isAuthenticatedSelector = (state) => state.user.isAuthenticated;
