import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allGroupsAPI } from "../api";

export const getAllGroups = createAsyncThunk(
  "allGroups/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await allGroupsAPI();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  groups: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "allGroups",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllGroups.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllGroups.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const allGroupsSelector = (state) => state.allGroups.groups;
export const allGroupsLoadingSelector = (state) => state.allGroups.loading;
export const allGroupsPaginationSelector = (state) => state.allGroups.pagination;
