import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { rolesAPI } from "../api";

export const getUsersRoles = createAsyncThunk(
  "usersRoles/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await rolesAPI();
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  roles: null,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "usersRoles",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersRoles.pending]: (state) => {
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getUsersRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getUsersRoles.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const usersRolesSelector = (state) => state.usersRoles.roles;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
