import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editTestAPI } from "../api";

export const editTest = createAsyncThunk(
  "editTest/fetch",
  async (challengeData, thunkAPI) => {
    try {
      const {id, data} = challengeData;
      const formData = new FormData();
      Object.entries(data).forEach((item) => {
        formData.append(item[0], item[1]);
      });
      const res = await editTestAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  dayId: null,
  testId: null,
  testType: null,
  position: null,
  updatedTest: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "editTest",
  initialState,
  reducers: {
    resetEditedTest: (state) => {
      state.testId = null;
      state.testType = null;
      state.position = null;
      state.updatedTest = null;
      return state;
    },
    setDayId: (state, { payload }) => {
        state.dayId = payload;
        return state;
    },
    setTestId: (state, { payload }) => {
      state.testId = payload;
      return state;
    },
    setTestType: (state, { payload }) => {
      state.testType = payload;
      return state;
    },
    setPosition: (state, { payload }) => {
        state.position = payload;
        return state;
    }
  },
  extraReducers: {
    [editTest.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [editTest.fulfilled]: (state, { payload }) => {
        state.updatedTest = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [editTest.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { 
    resetEditedTest, 
    setDayId, 
    setPosition,
    setTestId,
    setTestType 
} = slice.actions;

// Selectors
export const editTestDayIdSelector = (state) => state.editTest.dayId;
export const editTestIdSelector = (state) => state.editTest.testId;
export const editTestTypeSelector = (state) => state.editTest.testType;
export const editTestPositionSelector = (state) => state.editTest.position;
export const editUpdatedTestSelector = (state) => state.editTest.updatedTest;
export const editTestLoadingSelector = (state) => state.editTest.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
