import { combineReducers } from "redux";

import user from "../screens/auth/slices/authSlice";
import mainSidebar from '../screens/main/slices/sidebarSlice';
import subjects from '../pages/subjects/slices/allSubjectsSlice';

import ageClasses from '../pages/subjects/slices/ageClassesSlice';
import addSubject from '../pages/subjects/slices/addSubjectSlice';
import deleteSubject from '../pages/subjects/slices/deleteSubjectSlice';
import updateSubject from '../pages/subjects/slices/updateSubjectSlice';
import subjectAllMonths from '../pages/subjects/slices/allMonthsSlice';
import subjectAllWeeks from '../pages/subjects/slices/allWeeksSlice';
import subjectAllDays from '../pages/subjects/slices/allDaysSlice';
import deleteDay from '../pages/subjects/slices/deleteDaySlice';
import updateDay from '../pages/subjects/slices/updateDaySlice';
import subjectEditTestsOfDay from '../pages/subjects/slices/subjectEditTestsOfDaySlice';
import deleteTest from '../pages/subjects/slices/deleteTestSlice';
import editTest from '../pages/subjects/slices/updateTestSlice';
import subjectAllYears from '../pages/subjects/slices/allYearsSlice';
import subjectAddMonth from '../pages/subjects/slices/addMonthSlice';
import subjectAddWeek from '../pages/subjects/slices/addWeekSlice';
import deleteMonth from '../pages/subjects/slices/deleteMonthSlice';
import updateMonth from '../pages/subjects/slices/updateMonthSlice';
import deleteWeek from '../pages/subjects/slices/deleteWeekSlice';
import updateWeek from '../pages/subjects/slices/updateWeekSlice';
import subjectAddDay from '../pages/subjects/slices/addDaySlice';

import allSidebars from '../pages/sidebars/slices/allSidebarsSlice';
import addSidebar from '../pages/sidebars/slices/addSidebarSlice';
import updateSidebar from '../pages/sidebars/slices/updateSidebarSlice';

import allRoles from '../pages/roles/slices/allRolesSlice';
import addRole from '../pages/roles/slices/addRoleSlice';
import updateRole from '../pages/roles/slices/updateRoleSlice';
import deleteRole from '../pages/roles/slices/deleteRoleSlice';

import challengeAgeClasses from '../pages/challenge-create/slices/ageClassesSlice';
import challengeAllSubjects from '../pages/challenge-create/slices/subjectsSlice';
import challengeAllMonths from '../pages/challenge-create/slices/monthsSlice';
import challengeAllWeeks from '../pages/challenge-create/slices/weeksSlice';
import challengeAllDays from '../pages/challenge-create/slices/daysSlice';
import challengeType from '../pages/challenge-create/slices/typeSlice';
import allSubjectTestsOfDay from '../pages/challenge-create/slices/allSubjectTestsOfDaySlice';
import addChallenge from '../pages/challenge-create/slices/addChallengeSlice';

import editChallengeAgeClasses from '../pages/challenge-edit/slices/ageClassesSlice';
import editChallengeAllSubjects from '../pages/challenge-edit/slices/subjectsSlice';
import editChallengeAllMonths from '../pages/challenge-edit/slices/monthsSlice';
import editChallengeAllWeeks from '../pages/challenge-edit/slices/weeksSlice';
import editChallengeAllDays from '../pages/challenge-edit/slices/daysSlice';
import editChallengeType from '../pages/challenge-edit/slices/typeSlice';
import editChallengeAllSubjectTestsOfDay from '../pages/challenge-edit/slices/allSubjectTestsOfDaySlice';
import editChallengeTest from '../pages/challenge-edit/slices/editTestSlice';
import deleteChallengeTest from '../pages/challenge-edit/slices/deleteTestSlice';

import usersRegions from '../pages/users/slices/regionsSlice';
import usersCities from '../pages/users/slices/citiesSlice';
import usersKindergartens from '../pages/users/slices/kindergartensSlice';
import usersRoles from '../pages/users/slices/rolesSlice';
import addUser from '../pages/users/slices/addUserSlice';
import deleteUser from '../pages/users/slices/deleteUserSlice';
import allUsers from '../pages/users/slices/allUsersSlice';
import updateUser from '../pages/users/slices/updateUserSlice';

import kindergartensRegions from '../pages/kindergartens/slices/regionsSlice';
import kindergartensCities from '../pages/kindergartens/slices/citiesSlice';
import kindergartensAllUsers from '../pages/kindergartens/slices/allUsersSlice';
import addKindergarten from '../pages/kindergartens/slices/addKindergartenSlice';
import kindergartens from '../pages/kindergartens/slices/kindergartensSlice';
import deleteKindergarten from '../pages/kindergartens/slices/deleteKindergartenSlice';
import updateKindergarten from '../pages/kindergartens/slices/updateKindergartenSlice';

import allUsersForGroup from '../pages/kindergartens-panel/slices/allUsersSlice';
import groupAgeClasses from '../pages/kindergartens-panel/slices/ageClassesSlice';
import studyYears from '../pages/kindergartens-panel/slices/studyYearsSlice';
import allGroups from '../pages/kindergartens-panel/slices/allGroupsSlice';
import createStudent from '../pages/kindergartens-panel/slices/createStudentSlice';
import createParent from '../pages/kindergartens-panel/slices/createParentSlice';
import deleteStudent from '../pages/kindergartens-panel/slices/deleteStudentSlice';
import updateStudent from '../pages/kindergartens-panel/slices/updateStudentSlice';
import allStudents from '../pages/kindergartens-panel/slices/allStudentsSlice';

import subjectAgeClasses from '../pages/challenge/slices/ageClassesSlice';
import subjectAllSubjects from '../pages/challenge/slices/allSubjectsSlice';



const reducers = combineReducers({
  user,
  mainSidebar,
  subjects,
  ageClasses,
  addSubject,
  deleteSubject,
  updateSubject,
  subjectAllMonths,
  subjectAllWeeks,
  subjectAllDays,
  subjectAllYears,
  subjectAddMonth,
  subjectAddWeek,
  deleteMonth,
  updateMonth,
  deleteWeek,
  updateWeek,
  subjectAddDay,
  deleteDay,
  updateDay,
  subjectEditTestsOfDay,
  deleteTest,
  editTest,
  allSidebars, 
  addSidebar,
  updateSidebar,
  allRoles,
  addRole,
  updateRole,
  deleteRole,
  challengeAgeClasses,
  challengeAllSubjects,
  challengeAllMonths,
  challengeAllWeeks,
  challengeAllDays,
  challengeType,
  allSubjectTestsOfDay,
  addChallenge,
  editChallengeAgeClasses,
  editChallengeAllSubjects,
  editChallengeAllMonths,
  editChallengeAllWeeks,
  editChallengeAllDays,
  editChallengeType,
  editChallengeAllSubjectTestsOfDay,
  editChallengeTest,
  deleteChallengeTest,
  usersRegions,
  usersCities,
  usersKindergartens,
  usersRoles,
  addUser,
  deleteUser,
  allUsers,
  updateUser,
  kindergartensRegions,
  kindergartensCities,
  kindergartensAllUsers,
  addKindergarten,
  kindergartens,
  deleteKindergarten,
  updateKindergarten,
  allUsersForGroup,
  groupAgeClasses,
  studyYears,
  allGroups,
  createStudent,
  createParent,
  deleteStudent,
  updateStudent,
  allStudents,
  subjectAgeClasses,
  subjectAllSubjects
});

export default reducers;
