import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { regionsAPI } from "../api";

export const getUsersRegions = createAsyncThunk(
  "usersRegions/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await regionsAPI();
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  regions: null,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "usersRegions",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersRegions.pending]: (state) => {
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getUsersRegions.fulfilled]: (state, { payload }) => {
      state.regions = payload;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getUsersRegions.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const usersRegionsSelector = (state) => state.usersRegions.regions;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
