import { Result, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';


export const SubmitResult = ({status, title, subTitle, onClose, extra=[], children}) => {
    return (
        <Result
            status={status}
            title={title}
            subTitle={subTitle}
            extra={[
                ...extra,
                <Button type="primary" onClick={onClose} icon={<CloseCircleOutlined />} ghost key="close">Закрыть</Button>
            ]}
        >
            {children}
        </Result>
    )
}