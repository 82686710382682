import { 
  createSlice, 
  // createAsyncThunk 
} from "@reduxjs/toolkit";
// import { mainSidebarAPI } from "../api";

// export const getSidebarMenu = createAsyncThunk(
//   "sidebarMenu/fetch",
//   async (_, thunkAPI) => {
//     try {
//       console.log('Starting...')
//       const res = await mainSidebarAPI();
//       console.log(res);
      
//       return res.data.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue({ error: error.message });
//     }
//   }
// );

// Slice
const initialState = {
  collapsed: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "mainSidebar",
  initialState,
  reducers: {
    handleCollapse: (state) => {
      state.collapsed = !state.collapsed;
      return state;
    },
  },
  // extraReducers: {
  //   [getSidebarMenu.pending]: (state) => {
  //     state.hasErrors = false;
  //     state.error = "";
  //     return state;
  //   },
  //   [getSidebarMenu.fulfilled]: (state, { payload }) => {
  //     state.sidebar = payload;
  //     state.hasErrors = false;
  //     state.error = "";
  //     return state;
  //   },
  //   [getSidebarMenu.rejected]: (state, { error }) => {
  //     state.hasErrors = true;
  //     state.error = error.message;
  //     return state;
  //   },
  // },
});

export default slice.reducer;

// Actions
export const { handleCollapse } = slice.actions;

// Selectors
// export const sidebarSelector = (state) => state.mainSidebar.sidebar;
export const collapsedSelector = (state) => state.mainSidebar.collapsed;
