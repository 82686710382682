import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allMonthsAPI } from "../api";

export const getSubjectAllMonths = createAsyncThunk(
  "subjectAllMonths/fetch",
  async (subjectData, thunkAPI) => {
    try {
      const res = await allMonthsAPI(subjectData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
    subjectAllMonths: null,
    loading: false, 
    hasErrors: false,
    error: "",
};

const slice = createSlice({
  name: "subjectAllMonths",
  initialState,
  reducers: {
    resetAllMonths: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getSubjectAllMonths.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getSubjectAllMonths.fulfilled]: (state, { payload }) => {
        state.subjectAllMonths = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getSubjectAllMonths.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAllMonths } = slice.actions;

// Selectors
export const subjectAllMonthsSelector = (state) => state.subjectAllMonths.subjectAllMonths;
export const subjectAllMonthsLoadingSelector = (state) => state.subjectAllMonths.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
