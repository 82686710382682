import { 
    httpDelete, 
    httpGet, 
    httpPost,
    httpPut
 } from "../../../api";


export const allRolesAPI = () =>
  httpGet({
    url: "/api/admin/roles"
  });

export const addRoleAPI = (data) =>
  httpPost({
      url: "/api/admin/roles/create",
      data
  });

export const deleteRoleAPI = (id) =>
  httpDelete({
    url: `/api/admin/roles/delete/${id}`,
  });

export const updateRoleAPI = (id, data) =>
  httpPut({
    url: `/api/admin/roles/update/${id}`,
    data
  });

