import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { kindergartensAPI } from "../api";

export const getKindergartens = createAsyncThunk(
  "kindergartens/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await kindergartensAPI();
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
    kindergartens: null,
    loading: false, 
    hasErrors: false,
    error: "",
};

const slice = createSlice({
  name: "kindergartens",
  initialState,
  reducers: {},
  extraReducers: {
    [getKindergartens.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getKindergartens.fulfilled]: (state, { payload }) => {
      state.kindergartens = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getKindergartens.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.loading = false;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const kindergartensSelector = (state) => state.kindergartens.kindergartens;
export const kindergartensLoadingSelector = (state) => state.kindergartens.loading;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
