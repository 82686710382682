import { GlobalStyle } from "../theme/global-styles";
import { Root } from "./screens/root";


function App() {
  return (
    <>
      <GlobalStyle />
      <Root />
    </>
  );
}

export default App;
