import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allYearsAPI } from "../api";

export const getSubjectAllYears = createAsyncThunk(
  "subjectAllYears/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await allYearsAPI();
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
    subjectAllYears: null,
    // monthId: null,
    loading: false, 
    hasErrors: false,
    error: "",
};

const slice = createSlice({
  name: "subjectAllYears",
  initialState,
  reducers: {
    resetAllYears: (state) => {
      state = initialState;
      return state;
    },
    // setMonthId: (state, {payload}) => {
    //     state.monthId = payload;
    //     return state;
    // }
  },
  extraReducers: {
    [getSubjectAllYears.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getSubjectAllYears.fulfilled]: (state, { payload }) => {
        state.subjectAllYears = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getSubjectAllYears.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { 
    resetAllYears, 
    // setMonthId 
} = slice.actions;

// Selectors
export const subjectAllYearsSelector = (state) => state.subjectAllYears.subjectAllYears;
export const subjectAllYearsLoadingSelector = (state) => state.subjectAllYears.loading;
// export const subjectAllYearsMonthIdSelector = (state) => state.subjectAllWeeks.monthId;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
