import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateDayAPI } from "../api";

export const updateDay = createAsyncThunk(
  "updateDay/fetch",
  async (dayData, thunkAPI) => {
    try {
      const { id, data } = dayData;
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      const res = await updateDayAPI(id, formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  day: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateDay",
  initialState,
  reducers: {
    setUpdateDayId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateDay.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateDay.fulfilled]: (state, { payload }) => {
      state.day = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateDay.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateDayId, resetUpdated } = slice.actions;

// Selectors
export const updateDayLoadingSelector = (state) => state.updateDay.loading;
export const updateDayHasErrorsSelector = (state) => state.updateDay.hasErrors;
export const updateDayErrorMessageSelector = (state) => state.updateDay.error;
export const updateDaySelector = (state) => state.updateDay.day;
export const updateDayIdSelector = (state) => state.updateDay.id;
