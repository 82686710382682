import { createSlice } from "@reduxjs/toolkit";

// Slice
const initialState = {
  editChallengeType: 1,
};

const slice = createSlice({
  name: "editChallengeType",
  initialState,
  reducers: {
    setType: (state, { payload }) => {
      state.editChallengeType = payload;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setType } = slice.actions;

// Selectors
export const editChallengeTypeSelector = (state) => state.editChallengeType.editChallengeType;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
