import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editTestAPI } from "../api";

export const editChallengeTest = createAsyncThunk(
  "editChallengeTest/fetch",
  async (challengeData, thunkAPI) => {
    try {
      const {id, data} = challengeData;
      const formData = new FormData();
      Object.entries(data).forEach((item) => {
        formData.append(item[0], item[1]);
      });
      const res = await editTestAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  dayId: null,
  testId: null,
  testType: null,
  position: null,
  updatedTest: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "editChallengeTest",
  initialState,
  reducers: {
    resetEditedChallengeTest: (state) => {
      state.testId = null;
      state.testType = null;
      state.position = null;
      state.updatedTest = null;
      return state;
    },
    setDayId: (state, { payload }) => {
        state.dayId = payload;
        return state;
    },
    setTestId: (state, { payload }) => {
      state.testId = payload;
      return state;
    },
    setTestType: (state, { payload }) => {
      state.testType = payload;
      return state;
    },
    setPosition: (state, { payload }) => {
        state.position = payload;
        return state;
    }
  },
  extraReducers: {
    [editChallengeTest.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [editChallengeTest.fulfilled]: (state, { payload }) => {
        state.updatedTest = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [editChallengeTest.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { 
  resetEditedChallengeTest, 
  setDayId, 
  setPosition,
  setTestId,
  setTestType 
} = slice.actions;

// Selectors
export const editChallengeTestDayIdSelector = (state) => state.editChallengeTest.dayId;
export const editChallengeTestIdSelector = (state) => state.editChallengeTest.testId;
export const editChallengeTestTypeSelector = (state) => state.editChallengeTest.testType;
export const editChallengeTestPositionSelector = (state) => state.editChallengeTest.position;
export const editChallengeUpdatedTestSelector = (state) => state.editChallengeTest.updatedTest;
export const editChallengeTestLoadingSelector = (state) => state.editChallengeTest.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
