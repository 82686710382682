import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addWeekAPI } from "../api";

export const addWeek = createAsyncThunk(
  "subjectAddWeek/fetch",
  async (weekFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(weekFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await addWeekAPI(formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  week: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "subjectAddWeek",
  initialState,
  reducers: {
    resetAddedWeek: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addWeek.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addWeek.fulfilled]: (state, { payload }) => {
      state.week = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addWeek.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedWeek } = slice.actions;

// Selectors
export const subjectAddWeekLoadingSelector = (state) => state.subjectAddWeek.loading;
export const subjectAddWeekHasErrorsSelector = (state) => state.subjectAddWeek.hasErrors;
export const subjectAddWeekErrorMessageSelector = (state) => state.subjectAddWeek.error;
export const addedWeekSelector = (state) => state.subjectAddWeek.week;
