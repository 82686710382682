import { 
    httpDelete, 
    httpGet, 
    httpPost 
} from "../../../api";

export const studyYearsAPI = () =>
  httpGet({
    url: "/api/admin/study_years"
  });

export const ageClassesAPI = () =>
  httpGet({
      url: "/api/admin/age_classes"
  });

export const allUsersAPI = () =>
  httpGet({
      url: "/api/admin/users"
  });

export const createGroupAPI = (data) =>
  httpPost({
      url: "/api/admin/groups",
      data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });

export const allGroupsAPI = () =>
  httpGet({
      url: "/api/admin/groups"
  });

export const createStudentAPI = (data) =>
  httpPost({
      url: "/api/admin/students",
      data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });

export const createParentAPI = (data) =>
  httpPost({
    url: "/api/admin/parent_of_students",
    data,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });


export const allStudentsAPI = (params) =>
  httpGet({
    url: "/api/admin/students",
    params
  });

export const deleteStudentAPI = (id) =>
  httpDelete({
    url: `/api/admin/students/${id}`,
  });

export const updateStudentAPI = (id, data) =>
  httpPost({
    url: `/api/admin/students/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
