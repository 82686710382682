import { 
    httpDelete, 
    httpGet, 
    httpPost 
} from "../../../api";

export const regionsAPI = () =>
  httpGet({
    url: "/api/admin/regions"
  });

export const citiesAPI = (params) =>
  httpGet({
    url: "/api/admin/cities",
    params
  });

export const allUsersAPI = () =>
  httpGet({
      url: "/api/admin/users"
  });

export const addKindergartenAPI = (data) =>
  httpPost({
      url: "/api/admin/kindergartens",
      data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });

export const kindergartensAPI = () =>
  httpGet({
    url: "/api/admin/kindergartens"
  }); 

export const deleteKindergartenAPI = (id) =>
  httpDelete({
    url: `/api/admin/kindergartens/${id}`,
  });

export const updateKindergartenAPI = (id, data) =>
  httpPost({
    url: `/api/admin/kindergartens/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
