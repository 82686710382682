import { 
    httpDelete, 
    httpGet, 
    httpPost,
    // httpPut
 } from "../../../api";


export const allSubjectTestsOfDayAPI = (params) =>
  httpGet({
    url: "/api/admin/tests",
    params
  });



export const ageClassesAPI = () =>
    httpGet({
        url: "/api/admin/age_classes"
    });

export const allSubjectsAPI = (params) =>
  httpGet({
    url: "/api/admin/subjects",
    params
  });

export const allMonthsAPI = (params) =>
    httpGet({
        url: "/api/admin/months",
        params
    });

export const allWeeksAPI = (params) =>
    httpGet({
        url: "/api/admin/weeks",
        params
    });

export const allDaysAPI = (params) =>
    httpGet({
        url: "/api/admin/days",
        params
    });

export const deleteTestAPI = (id) =>
    httpDelete({
      url: `/api/admin/tests/${id}`,
    });
  
export const editTestAPI = (id, data) =>
    httpPost({
        url: `/api/admin/tests/${id}`,
        data,
        headers: {
          'Content-Type': 'multipart/form-data'   
        }
    });

