import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allDaysAPI } from "../api";

export const getSubjectAllDays = createAsyncThunk(
  "subjectAllDays/fetch",
  async (weekData, thunkAPI) => {
    try {
      const res = await allDaysAPI(weekData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
    subjectAllDays: null,
    weekId: null,
    loading: false, 
    hasErrors: false,
    error: "",
};

const slice = createSlice({
  name: "subjectAllDays",
  initialState,
  reducers: {
    resetAllDays: (state) => {
      state = initialState;
      return state;
    },
    setWeekId: (state, {payload}) => {
        state.weekId = payload;
        return state;
    }
  },
  extraReducers: {
    [getSubjectAllDays.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getSubjectAllDays.fulfilled]: (state, { payload }) => {
        state.subjectAllDays = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getSubjectAllDays.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAllDays, setWeekId } = slice.actions;

// Selectors
export const subjectAllDaysSelector = (state) => state.subjectAllDays.subjectAllDays;
export const subjectAllDaysLoadingSelector = (state) => state.subjectAllDays.loading;
export const subjectAllDaysWeekIdSelector = (state) => state.subjectAllDays.weekId;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
