import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSubjectAPI } from "../api";

export const addSubject = createAsyncThunk(
  "addSubject/fetch",
  async (subjectFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(subjectFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await addSubjectAPI(formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  subject: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addSubject",
  initialState,
  reducers: {
    resetAddedSubject: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addSubject.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addSubject.fulfilled]: (state, { payload }) => {
      state.subject = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addSubject.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedSubject } = slice.actions;

// Selectors
export const addSubjectLoadingSelector = (state) => state.addSubject.loading;
export const addSubjectHasErrorsSelector = (state) => state.addSubject.hasErrors;
export const addSubjectErrorMessageSelector = (state) => state.addSubject.error;
export const addedSubjectSelector = (state) => state.addSubject.subject;
