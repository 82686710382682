import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addChallengeAPI } from "../api";

export const addChallenge = createAsyncThunk(
  "addChallenge/fetch",
  async (challengeData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(challengeData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('formData in slice^', formData);
      const res = await addChallengeAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  dayId: null,
  position: null,
  res: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addChallenge",
  initialState,
  reducers: {
    resetAddedChallenge: (state) => {
      state = initialState;
      return state;
    },
    setDayId: (state, { payload }) => {
        state.dayId = payload;
        return state;
    },
    setPosition: (state, { payload }) => {
        state.position = payload;
        return state;
    }
  },
  extraReducers: {
    [addChallenge.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [addChallenge.fulfilled]: (state, { payload }) => {
        state.res = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [addChallenge.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedChallenge, setDayId, setPosition } = slice.actions;

// Selectors
export const addChallengeDayIdSelector = (state) => state.addChallenge.dayId;
export const addChallengePositionSelector = (state) => state.addChallenge.position;
export const addChallengeLoadingSelector = (state) => state.addChallenge.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
