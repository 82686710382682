import styled from 'styled-components/macro';
import { Input } from 'antd';


export const FormInput = styled(Input)`
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;

    .ant-input {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;