import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allMonthsAPI } from "../api";

export const getChallengeAllMonths = createAsyncThunk(
  "challengeAllMonths/fetch",
  async (subjectData, thunkAPI) => {
    try {
      const res = await allMonthsAPI(subjectData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  challengeAllMonths: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "challengeAllMonths",
  initialState,
  reducers: {
    resetAllMonths: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getChallengeAllMonths.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getChallengeAllMonths.fulfilled]: (state, { payload }) => {
        state.challengeAllMonths = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getChallengeAllMonths.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAllMonths } = slice.actions;

// Selectors
export const challengeAllMonthsSelector = (state) => state.challengeAllMonths.challengeAllMonths;
export const challengeAllMonthsLoadingSelector = (state) => state.challengeAllMonths.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
