import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSidebarAPI } from "../api";

export const addSidebar = createAsyncThunk(
  "addSidebar/fetch",
  async (formData, thunkAPI) => {
    try {
      console.log(formData);
      const res = await addSidebarAPI(formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  sidebar: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addSidebar",
  initialState,
  reducers: {
    resetAddedSidebar: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addSidebar.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addSidebar.fulfilled]: (state, { payload }) => {
      state.sidebar = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addSidebar.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedSidebar } = slice.actions;

// Selectors
export const addSidebarLoadingSelector = (state) => state.addSidebar.loading;
export const addSidebarHasErrorsSelector = (state) => state.addSidebar.hasErrors;
export const addSidebarErrorMessageSelector = (state) => state.addSidebar.error;
export const addedSidebarSelector = (state) => state.addSidebar.sidebar;
