import { httpDelete, httpGet, httpPost } from "../../../api";

export const allSubjectsAPI = (params) =>
  httpGet({
    url: "/api/admin/subjects",
    params
  });

export const ageClassesAPI = () =>
  httpGet({
    url: "/api/admin/age_classes"
  });

export const getSubjectAPI = (id) =>
  httpGet({
    url: `/api/admin/subjects/${id}`
  });

export const addSubjectAPI = (data) =>
  httpPost({
    url: "/api/admin/subjects",
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const deleteSubjectAPI = (id) =>
  httpDelete({
    url: `/api/admin/subjects/${id}`,
  });

export const updateSubjectAPI = (id, data) =>
  httpPost({
    url: `/api/admin/subjects/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const allMonthsAPI = (params) =>
  httpGet({
    url: `/api/admin/months`,
    params
  });

export const allWeeksAPI = (params) =>
  httpGet({
    url: `/api/admin/weeks`,
    params
  });

export const allDaysAPI = (params) =>
  httpGet({
    url: `/api/admin/days`,
    params
  });

export const updateDayAPI = (id, data) =>
  httpPost({
    url: `/api/admin/days/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const deleteDayAPI = (id) =>
  httpDelete({
    url: `/api/admin/days/${id}`,
  });

export const allSubjectTestsOfDayAPI = (params) =>
  httpGet({
    url: "/api/admin/tests",
    params
  });

export const deleteTestAPI = (id) =>
  httpDelete({
    url: `/api/admin/tests/${id}`,
  });

export const editTestAPI = (id, data) =>
  httpPost({
      url: `/api/admin/tests/${id}`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data'   
      }
  });

export const allYearsAPI = () =>
  httpGet({
    url: `/api/admin/study_years`
  });

export const addMonthAPI = (data) =>
  httpPost({
    url: `/api/admin/months`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'   
    }
  });

export const addWeekAPI = (data) =>
  httpPost({
    url: `/api/admin/weeks`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'   
    }
  });

export const deleteMonthAPI = (id) =>
  httpDelete({
    url: `/api/admin/months/${id}`,
  });

export const updateMonthAPI = (id, data) =>
  httpPost({
    url: `/api/admin/months/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const deleteWeekAPI = (id) =>
  httpDelete({
    url: `/api/admin/weeks/${id}`,
  });

export const updateWeekAPI = (id, data) =>
  httpPost({
    url: `/api/admin/weeks/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const addDayAPI = (data) =>
  httpPost({
    url: `/api/admin/days`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'   
    }
  });
