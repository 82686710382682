import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateRoleAPI } from "../api";

export const updateRole = createAsyncThunk(
  "updateRole/fetch",
  async (roleData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = roleData;
      const res = await updateRoleAPI(id, data);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  role: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateRole",
  initialState,
  reducers: {
    setUpdateRoleId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateRole.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateRole.fulfilled]: (state, { payload }) => {
      state.role = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateRole.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateRoleId, resetUpdated } = slice.actions;

// Selectors
export const updateRoleLoadingSelector = (state) => state.updateRole.loading;
export const updateRoleHasErrorsSelector = (state) => state.updateRole.hasErrors;
export const updateRoleErrorMessageSelector = (state) => state.updateRole.error;
export const updatedRoleSelector = (state) => state.updateRole.role;
export const updatedRoleIdSelector = (state) => state.updateRole.id;
