import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allWeeksAPI } from "../api";

export const getChallengeAllWeeks = createAsyncThunk(
  "challengeAllWeeks/fetch",
  async (monthData, thunkAPI) => {
    try {
      const res = await allWeeksAPI(monthData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  challengeAllWeeks: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "challengeAllWeeks",
  initialState,
  reducers: {
    resetAllWeeks: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getChallengeAllWeeks.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [getChallengeAllWeeks.fulfilled]: (state, { payload }) => {
        state.challengeAllWeeks = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [getChallengeAllWeeks.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAllWeeks } = slice.actions;

// Selectors
export const challengeAllWeeksSelector = (state) => state.challengeAllWeeks.challengeAllWeeks;
export const challengeAllWeeksLoadingSelector = (state) => state.challengeAllWeeks.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
