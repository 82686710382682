import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateUserAPI } from "../api";

export const updateUser = createAsyncThunk(
  "updateUser/fetch",
  async (userData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = userData;
      console.log('check: ', data);
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      console.log(formData);
      const res = await updateUserAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  user: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {
    setUpdateUserId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateUser.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateUserId, resetUpdated } = slice.actions;

// Selectors
export const updateUserLoadingSelector = (state) => state.updateUser.loading;
export const updateUserHasErrorsSelector = (state) => state.updateUser.hasErrors;
export const updateUserErrorMessageSelector = (state) => state.updateUser.error;
export const updatedUserSelector = (state) => state.updateUser.user;
export const updateUserIdSelector = (state) => state.updateUser.id;
