import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allStudentsAPI } from "../api";

export const getAllStudents = createAsyncThunk(
  "allStudents/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await allStudentsAPI();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  students: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "allStudents",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllStudents.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllStudents.fulfilled]: (state, { payload }) => {
      state.students = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllStudents.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const allStudentsSelector = (state) => state.allStudents.students;
export const allStudentsLoadingSelector = (state) => state.allStudents.loading;
export const allStudentsPaginationSelector = (state) => state.allStudents.pagination;
