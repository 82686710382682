import styled from 'styled-components/macro';
import { Form } from 'antd';

import SignInBgImg from '../../../../assets/img/sign-in-bg.jpg';

export const SignInPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    background: url(${SignInBgImg}) no-repeat center center;
    background-size: cover;
`;

export const SignInFormOutter = styled.div`
    width: 320px;
    margin: 0 auto;
    padding: 35px 15px 12px;
    border-radius: 5px;

    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 55, 141, 199, 0.37 );
    backdrop-filter: blur( 10.0px );
    -webkit-backdrop-filter: blur( 10.0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
`;

export const SignInForm = styled(Form)`
    text-align: center;
`;