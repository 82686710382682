import React, { useState } from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import { signIn } from '../slices/authSlice';
import { FormButton, SignInForm, FormInput } from '../atoms';

export const Form = () => {

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(signIn(values));
    setLoading(false);
    history.push('/');
  };

  return (
    <SignInForm
      name="sign-in"
      onFinish={onFinish}
    >
      <SignInForm.Item
        name="phone"
        rules={[
          {
            required: true,
            message: 'Пожалуйста введите логин!',
          },
        ]}
      >
        <FormInput prefix={<UserOutlined />} placeholder="Логин" />
      </SignInForm.Item>
      <SignInForm.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Пожалуйста введите пароль!',
          },
        ]}
      >
        <FormInput
          prefix={<LockOutlined />}
          type="password"
          placeholder="Пароль"
        />
      </SignInForm.Item>

      <SignInForm.Item>
        <FormButton type="primary" htmlType="submit" loading={loading}>
          Войти
        </FormButton>
      </SignInForm.Item>
    </SignInForm>
  );
};