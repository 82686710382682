import { 
    httpDelete, 
    httpGet, 
    httpPost,
    httpPut
 } from "../../../api";


export const allSidebarsAPI = () =>
  httpGet({
    url: "/api/admin/sidebars"
  });

export const addSidebarAPI = (data) =>
  httpPost({
      url: "/api/admin/sidebars",
      data
  });

export const deleteSidebarAPI = (id) =>
  httpDelete({
    url: `/api/admin/sidebars/${id}`,
  });

export const updateSidebarAPI = (id, data) =>
  httpPut({
    url: `/api/admin/sidebars/${id}`,
    data
  });

