import styled from 'styled-components/macro';

export const PageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;

    section {
        height: 100%;
    }
`;