import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
// import * as _ from 'lodash';

import { SidebarLogo } from '../molecules';  
import { setIcon } from '../../../components/molecules';
import { 
    // sidebarSelector, 
    collapsedSelector, 
    // getSidebarMenu 
} from '../slices/sidebarSlice';
import { userSelector } from '../../auth/slices/authSlice';

const { Sider } = Layout;
const { SubMenu } = Menu;

export const Sidebar = () => {

    // const dispatch = useDispatch();
    // const sidebarMenu = useSelector(sidebarSelector);
    const collapsed = useSelector(collapsedSelector);
    const currentUser = useSelector(userSelector);
    const [menu, setMenu] = useState([]);

    // console.log(sidebarMenu);
   
    // const fetchMenu = async () => {
    //     await dispatch(getSidebarMenu());
    // }

    const getSidebars = () => {
        if (currentUser) {
            const sidebars = currentUser.roles[0].sidebars;
            setMenu(sidebars);
        }
    }

    useEffect(() => {
        getSidebars(); 
    }, [currentUser]);

    const menuRender = () => {
        let menuItems = [];
        menu.forEach(item => {
            if (item.parent_id) {
                return null;
            }
            if (item.url === "#") {
                const filteredMenuItems = menu.filter(el => Number(el.parent_id) === Number(item.id));

                filteredMenuItems.length > 0 ? 
                    (menuItems.push(
                        <SubMenu 
                            key={item.id} 
                            icon={setIcon(item.icon)} 
                            title={item.name} 
                        >
                            {filteredMenuItems.map(children => (
                                    <Menu.Item key={children.id} icon={setIcon(children.icon)}>
                                        <Link to={children.url}>
                                            {children.name}
                                        </Link> 
                                    </Menu.Item>  
                            ))}
                        </SubMenu>)
                    ) : (
                        menuItems.push(null)            
                    )
            } else {
                menuItems.push(
                    <Menu.Item key={item.id} icon={setIcon(item.icon)}>
                        <Link to={item.url}>
                            {item.name}
                        </Link> 
                    </Menu.Item> 
                )
            }
        });
        return menuItems;
    }
    

    return (
        <Sider 
            trigger={null} 
            collapsible 
            collapsed={collapsed}
            width={230}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            <SidebarLogo />
                {
                    menu ? (
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                            {menuRender()}
                        </Menu>
                    ) : (
                        setIcon('InfoOutlined')
                    )
                }
        </Sider>
    )
}