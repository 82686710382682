import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { studyYearsAPI } from "../api";

export const getStudyYears = createAsyncThunk(
  "studyYears/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await studyYearsAPI();
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  studyYears: null,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "studyYears",
  initialState,
  reducers: {},
  extraReducers: {
    [getStudyYears.pending]: (state) => {
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getStudyYears.fulfilled]: (state, { payload }) => {
      state.studyYears = payload;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getStudyYears.rejected]: (state, { error }) => {
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const studyYearsSelector = (state) => state.studyYears.studyYears;
// export const collapsedSelector = (state) => state.mainSidebar.collapsed;
