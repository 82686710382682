import { createSlice } from "@reduxjs/toolkit";

// Slice
const initialState = {
  challengeType: 1,
};

const slice = createSlice({
  name: "challengeType",
  initialState,
  reducers: {
    setType: (state, { payload }) => {
      state.challengeType = payload;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setType } = slice.actions;

// Selectors
export const challengeTypeSelector = (state) => state.challengeType.challengeType;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
