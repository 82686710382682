import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  } from '@ant-design/icons';


import { collapsedSelector, handleCollapse } from '../slices/sidebarSlice';

const { Header } = Layout;

export const TopHeader = () => {

    const collapsed = useSelector(collapsedSelector);
    const dispatch = useDispatch();

    return (
        <Header className="site-layout-background" style={{ padding: '0' }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => dispatch(handleCollapse()),
                style: {color: '#ffffff', padding: '0 0 0 20px'},
            })}
        </Header>
    )
}
