import { Row, Col } from 'antd'; 

import { SignInPageWrapper, SignInFormOutter } from './atoms';
import { FormHeader } from './molecules';
import { Form } from './organisms';

export const SignIn = () => {
    return (
        <SignInPageWrapper>
            <SignInFormOutter>
                <Row justify='center' align='middle'>
                    <Col>
                        <FormHeader />
                    </Col>
                    <Col>
                        <Form />
                    </Col>
                </Row>
            </SignInFormOutter>
        </SignInPageWrapper>
    )
}