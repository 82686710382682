import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateMonthAPI } from "../api";

export const updateMonth = createAsyncThunk(
  "updateMonth/fetch",
  async (monthData, thunkAPI) => {
    try {
      const { id, data } = monthData;
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      const res = await updateMonthAPI(id, formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  month: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateMonth",
  initialState,
  reducers: {
    setUpdateMonthId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateMonth.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateMonth.fulfilled]: (state, { payload }) => {
      state.month = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateMonth.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateMonthId, resetUpdated } = slice.actions;

// Selectors
export const updateMonthLoadingSelector = (state) => state.updateMonth.loading;
export const updateMonthHasErrorsSelector = (state) => state.updateMonth.hasErrors;
export const updateMonthErrorMessageSelector = (state) => state.updateMonth.error;
export const updateMonthSelector = (state) => state.updateMonth.month;
export const updateMonthIdSelector = (state) => state.updateMonth.id;
