import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addDayAPI } from "../api";

export const addDay = createAsyncThunk(
  "subjectAddDay/fetch",
  async (dayFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(dayFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await addDayAPI(formData);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  day: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "subjectAddDay",
  initialState,
  reducers: {
    resetAddedMonth: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addDay.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addDay.fulfilled]: (state, { payload }) => {
      state.day = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addDay.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedDay } = slice.actions;

// Selectors
export const subjectAddDayLoadingSelector = (state) => state.subjectAddDay.loading;
export const subjectAddDayHasErrorsSelector = (state) => state.subjectAddDay.hasErrors;
export const subjectAddDayErrorMessageSelector = (state) => state.subjectAddDay.error;
export const addedDaySelector = (state) => state.subjectAddDay.day;
