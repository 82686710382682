import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Row, Col } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { Switch, Route, Redirect } from "react-router-dom";

import { Sidebar, TopHeader } from './organisms';
import { collapsedSelector } from './slices/sidebarSlice';
import { isAuthenticatedSelector } from '../auth/slices/authSlice';

const { Content } = Layout;

const HomePage = lazy(() => import("../../pages/home"));
const KindergartensPage = lazy(() => import("../../pages/kindergartens"));
const StaffPage = lazy(() => import("../../pages/staff"));
const SubjectsPage = lazy(() => import("../../pages/subjects"));
const StudentsPage = lazy(() => import("../../pages/students"));
const ParentsPage = lazy(() => import("../../pages/parents"));
const SchedulePage = lazy(() => import("../../pages/schedule"));
const DishesPage = lazy(() => import("../../pages/dishes"));
const SidebarsPage = lazy(() => import("../../pages/sidebars"));
const RolesPage = lazy(() => import("../../pages/roles"));
const ChallengeCreatePage = lazy(() => import("../../pages/challenge-create"));
const ChallengeEditPage = lazy(() => import("../../pages/challenge-edit"));
const UsersPage = lazy(() => import("../../pages/users"));
const KindergartensPanelPage = lazy(() => import("../../pages/kindergartens-panel"));
const ChallengePage = lazy(() => import("../../pages/challenge"));
// const NotFoundPage = lazy(() => import("../notFound"));



export const Main = () => {
  
    // const dispatch = useDispatch();
    const collapsed = useSelector(collapsedSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    return (
        <>
            { isAuthenticated ? ( 
                <Layout style={{ marginLeft: collapsed ? 80 : 230, transition: 'all 0.3s ease' }}>
                    <Sidebar />
                    <Layout className="site-layout">
                        <TopHeader />
                        <Content
                        className="site-layout-background"
                        style={{
                            padding: 30,
                            minHeight: 280,
                            background: '#ececec'
                        }}
                        >
                            <Suspense
                                fallback={
                                <Row justify='center' align='middle' style={{ height: "100vh" }}>
                                    <Col>
                                        <SyncOutlined
                                            style={{ fontSize: "56px", color: "#06667c" }}
                                            spin
                                        />
                                    </Col>
                                </Row>
                                }
                            >
                                <Switch>
                                    <Route exact path='/'>
                                        <HomePage />
                                    </Route>
                                    <Route exact path='/staff'>
                                        <StaffPage />
                                    </Route>
                                    <Route exact path='/subjects'>
                                        <SubjectsPage />
                                    </Route>
                                    <Route exact path='/students'>
                                        <StudentsPage />
                                    </Route>
                                    <Route exact path='/parents'>
                                        <ParentsPage />
                                    </Route>
                                    <Route exact path='/schedule'>
                                        <SchedulePage />
                                    </Route>
                                    <Route exact path='/dishes'>
                                        <DishesPage />
                                    </Route>
                                    <Route exact path='/sidebars'>
                                        <SidebarsPage />
                                    </Route>
                                    <Route exact path='/roles'>
                                        <RolesPage />
                                    </Route>
                                    <Route exact path='/challenge-create'>
                                        <ChallengeCreatePage />
                                    </Route>
                                    <Route exact path='/challenge-edit'>
                                        <ChallengeEditPage />
                                    </Route>
                                    <Route path='/users'>
                                        <UsersPage />
                                    </Route>
                                    <Route path='/kindergartens'>
                                        <KindergartensPage />
                                    </Route>
                                    <Route path='/kindergartens-panel'>
                                        <KindergartensPanelPage />
                                    </Route>
                                    <Route path='/challenge/:subjectId'>
                                        <ChallengePage />
                                    </Route>
                                    <Route exact path='/home'>
                                        <Redirect to='/' />
                                    </Route>
                                    {/* <Route path='*'>
                                        <NotFoundPage />
                                    </Route> */}
                                </Switch>
                            </Suspense>
                        </Content>
                    </Layout>
                </Layout> 
                ) : (
                    <Redirect to='/sign-in' />
                )
            }
        </>
    );
}
