import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {PageWrapper } from "../../components/atoms";
import { SignIn } from "../auth";
import { Main } from "../main";
import { NotFoundPage } from "../notFound";

export const Root = () => {

  return (
      <PageWrapper>
        <Router>
            <Switch>
                <Route exact path='/sign-in'>
                    <SignIn />
                </Route>
                <Route path='/'>
                    <Main />
                </Route>
                <Route path='*'>
                    <NotFoundPage />
                </Route>
            </Switch>
        </Router>
      </PageWrapper>
  );
};
