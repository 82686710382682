import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateStudentAPI } from "../api";

export const updateStudent = createAsyncThunk(
  "updateStudent/fetch",
  async (studentData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = studentData;
      console.log('check: ', data);
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      console.log(formData);
      const res = await updateStudentAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  student: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateStudent",
  initialState,
  reducers: {
    setUpdateStudentId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateStudent.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateStudent.fulfilled]: (state, { payload }) => {
      state.student = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateStudent.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateStudentId, resetUpdated } = slice.actions;

// Selectors
export const updateStudentLoadingSelector = (state) => state.updateStudent.loading;
export const updateStudentHasErrorsSelector = (state) => state.updateStudent.hasErrors;
export const updateStudentErrorMessageSelector = (state) => state.updateStudent.error;
export const updatedStudentSelector = (state) => state.updateStudent.student;
export const updateStudentIdSelector = (state) => state.updateStudent.id;
