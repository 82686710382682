import { Typography } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const FormHeader = () => {
    return (
        <Title level={3} style={{color: '#ffffff'}}>
            <TeamOutlined style={{color: '#1890ff'}} /> Вход в систему
        </Title>
    )
}