import styled from 'styled-components/macro';
import { Button } from 'antd';

const PrimaryButton = styled(Button)`
`;

export const PrimaryBtn = ({text, onClick, ...props}) => {
    return (
        <PrimaryButton
            {...props} 
            type="primary" 
            onClick={onClick} 
        >
            {text}
        </PrimaryButton>
    )
}